import React, { useState } from "react";
import { Form, Col, Button, Card, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

function SuccessModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Membership Application
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Successfully Saved</h4>
        <p>
          If navigating away from the Account pages, updates to your application will be visible after re-authenticating.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function FailModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Membership Application
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Oops!</h4>
        <p>
          Your application was not saved.  Please try again. 
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const InputForm = props => {
  const user = props.user;
  const token = props.token;
  const memberApplication = props.memberApplication;

  // Similar to componentDidMount and componentDidUpdate:
  // useEffect() to retrieve application from user prop passed in



  // useEffect() to retrieve application data each time navigated
/*   useEffect(() => {
    async function fetchData() {
      const url = `https://${process.env.GATSBY_AUTH0_DOMAIN}/userinfo`
      const options = {
        method: 'GET',
        cache: 'no-store',
        headers: {
          Authorization: 'Bearer ' + token.accessToken,
        }
      }
      const response = await fetch(url, options)
      const data = await response.json();
      if (typeof ((data || {})['https://isc2chapter-sacramento.org/user_metadata'] || {})['memberApplication'] != "undefined") {
          const fetchedApplication = data['https://isc2chapter-sacramento.org/user_metadata']['memberApplication'];
          setFormState(fetchedApplication)
      }
    }
    fetchData();
  }, []); */


  const [modalShow, setModalShow] = useState(false);
  const [failModalShow, setFailModalShow] = useState(false);
  const validationSchema = yup.object().shape({
    firstname: yup.string()
      .max(50,'Must be less than 50 characters')
      .required('Required'),
    lastname: yup.string()
      .max(50, 'Must be less than 50 characters')
      .required('Required'),
    title: yup.string()
      .max(50, 'Must be less than 50 characters')
      .required('Required'),
    employer: yup.string()
      .max(100, 'Must be less than 100 characters')
      .required('Required'),
    address1: yup.string()
      .max(100, 'Must be less than 100 characters')
      .required('Required'),
    address2: yup.string()
      .max(100, 'Must be less than 100 characters'),
    city: yup.string()
      .max(50, 'Must be less than 50 characters')
      .required('Required'),
    state: yup.string()
      .max(2, 'Please use 2 character state abbreviation')
      .matches(/^[A-Za-z]*$/, 'Please use 2 character state abbreviation')
      .required('Required'),
    zip: yup.string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid Zipcode')
      .required('Required'),
    primaryPhone: yup.string()
      .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, 'Invalid phone number'),
    primaryEmail: yup.string()
      .email('Invalid email')
      .required('Required'),
    secondaryEmail: yup.string()
      .email('Invalid email'),
    isc2Member: yup.bool(),
    isc2Id: yup.number()
      .when('isc2Member', {
        is: true,
        then: yup.number()
                  .typeError('Invalid ID')
                  .integer('Invalid ID')
                  .positive('Invalid ID')
                  .required('Required if member checkbox marked True')
                  .max(9999999999999999999999999, 'Must be less than 25 numbers'),
        otherwise: yup.number()
                    .typeError('Invalid ID')
                    .integer('Invalid ID')
                    .positive('Invalid ID')
                    .max(9999999999999999999999999, 'Must be less than 25 numbers')
      }),
    otherProfessionalAssociations: yup.string()
      .max(500, 'Must be less than 500 characters'),
    professionalCertificates: yup.string()
      .max(500, 'Must be less than 500 characters'),
    areasOfSpecialization: yup.string()
      .max(500, 'Must be less than 500 characters'),
    chapterLeadership: yup.bool(),
    professionalSpeaking: yup.bool(),
    educationPrograms: yup.bool(),
    communityOutreach: yup.bool(),
    chapterEvents: yup.bool(),
    otherContributionCheckbox: yup.bool(),
    otherContributionText: yup.string()
      .when('otherContributionCheckbox', {
        is: true,
        then: yup.string().max(100, 'Must be less than 100 characters').required('Required if Other checkbox marked True'),
        otherwise: yup.string().max(100,'Must be less than 100 characters')
      }),
    feedbackText: yup.string()
      .max(500, 'Must be less than 500 characters'),
    membershipAgreement: yup.bool()
      .oneOf([true], 'Please agree to the rules and requirements to proceed')
      .required('Please agree to the rules and requirements to proceed')


  });

  return(
    <>
      <Formik
        enableReinitialize
        initialValues={memberApplication}
        onSubmit={ (values, actions) => {
          const url = `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;
          const memberApplication = {"memberApplication": values}
          const options = {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token.accessToken}`
            },
            body: JSON.stringify({
              user_metadata: memberApplication
            })
          };
    
          fetch(url, options)
          .then(response => {
            setModalShow(true);
            props.setMemberApplication(values);
          })
          .catch(error => {
            console.log(error);
            setFailModalShow(true);
          });
          actions.setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {props => (
            <Form onSubmit={props.handleSubmit}>
            <Card>
              <Card.Header>Member Contact Information</Card.Header>
              <Card.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="firstname">
                    <Form.Label>Firstname</Form.Label>
                    <Form.Control 
                      placeholder="Firstname"
                      value={props.values.firstname || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.firstname}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.firstname}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="lastname">
                    <Form.Label>Lastname</Form.Label>
                    <Form.Control
                      placeholder="Lastname"
                      value={props.values.lastname || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.lastname}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.lastname}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      placeholder="Title"
                      value={props.values.title || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.title}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.title}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="employer">
                    <Form.Label>Employer</Form.Label>
                    <Form.Control
                      placeholder="Employer"
                      value={props.values.employer || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.employer}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.employer}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="address1">
                  <Form.Label>Address1</Form.Label>
                  <Form.Control
                    placeholder="1234 Main Street"
                    value={props.values.address1 || ''}
                    onChange={props.handleChange}
                    isInvalid={props.errors.address1}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors.address1}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="address2">
                  <Form.Label>Address2</Form.Label>
                  <Form.Control
                    placeholder="Build, Floor, Suite"
                    value={props.values.address2 || ''}
                    onChange={props.handleChange}
                    isInvalid={props.errors.address2}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors.address2}</Form.Control.Feedback>
                </Form.Group>
                <Form.Row>
                  <Form.Group as={Col} controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control placeholder="City"
                      value={props.values.city || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.city}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.city}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      placeholder="State"
                      value={props.values.state || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.state}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.state}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="zip">
                    <Form.Label>ZipCode</Form.Label>
                    <Form.Control
                      placeholder="Zip Code"
                      value={props.values.zip || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.zip}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.zip}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="primaryPhone">
                  <Form.Label>Primary Phone Number</Form.Label>
                  <Form.Control
                    placeholder="(555) 555-5555"
                    value={props.values.primaryPhone || ''}
                    onChange={props.handleChange}
                    isInvalid={props.errors.primaryPhone}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors.primaryPhone}</Form.Control.Feedback>
                </Form.Group>
                <Form.Row>
                  <Form.Group as={Col} controlId="primaryEmail">
                    <Form.Label>Primary Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="email1@example.com"
                      value={props.values.primaryEmail || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.primaryEmail}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.primaryEmail}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="secondaryEmail">
                    <Form.Label>Secondary Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="email2@example.com"
                      value={props.values.secondaryEmail || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.secondaryEmail}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.secondaryEmail}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>Membership Affiliation</Card.Header>
              <Card.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="isc2Member">
                    <Form.Check 
                      type="checkbox"
                      label="Are you a member of (ISC)²?"
                      value={props.values.isc2Member || ''}
                      checked={props.values.isc2Member}
                      onChange={props.handleChange}
                      isInvalid={props.errors.isc2Member}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.isc2Member}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="isc2Id">
                    <Form.Label>If so, please enter your (ISC)² ID Number</Form.Label>
                    <Form.Control
                      placeholder="(ISC)² ID number"
                      value={props.values.isc2Id || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.isc2Id}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.isc2Id}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="otherProfessionalAssociations">
                  <Form.Label>List other professional associations in which you are a member</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={props.values.otherProfessionalAssociations || ''}
                    onChange={props.handleChange}
                    isInvalid={props.errors.otherProfessionalAssociations}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors.otherProfessionalAssociations}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="professionalCertificates">
                  <Form.Label>List the professional certifications that you hold</Form.Label>
                  <Form.Control as="textarea"
                    placeholder="CISSP, PMP, CCNA"
                    value={props.values.professionalCertificates || ''}
                    onChange={props.handleChange}
                    isInvalid={props.errors.professionalCertificates}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors.professionalCertificates}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="areasOfSpecialization">
                  <Form.Label>Indicate your areas of specialization</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={props.values.areasOfSpecialization || ''}
                    onChange={props.handleChange}
                    isInvalid={props.errors.areasOfSpecialization}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors.areasOfSpecialization}</Form.Control.Feedback>
                </Form.Group>
                <Form.Label>Indicate your areas of interest in which you would like to participate to (ISC)² Chapters by checking the items below</Form.Label>
                <Form.Row>
                  <Form.Group as={Col} controlId="chapterLeadership">
                    <Form.Check 
                      type="checkbox"
                      label="Chapter Leadership/Management" 
                      value={props.values.chapterLeadership || ''}
                      checked={props.values.chapterLeadership} 
                      onChange={props.handleChange} 
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="professionalSpeaking">
                    <Form.Check 
                      type="checkbox"
                      label="Professional Speaking"
                      value={props.values.professionalSpeaking || ''} 
                      checked={props.values.professionalSpeaking} 
                      onChange={props.handleChange} 
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="educationPrograms">
                    <Form.Check 
                      type="checkbox"
                      label="Chapter Education Programs"
                      value={props.values.educationPrograms || ''} 
                      checked={props.values.educationPrograms} 
                      onChange={props.handleChange} 
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="communityOutreach">
                    <Form.Check 
                      type="checkbox"
                      label="SSO Volunteer / Chapter Outreach" 
                      value={props.values.communityOutreach || ''} 
                      checked={props.values.communityOutreach} 
                      onChange={props.handleChange} 
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} >
                    <Form.Check 
                      type="checkbox"
                      name="chapterEvents"
                      label="Chapter Events"
                      value={props.values.chapterEvents || ''}
                      checked={props.values.chapterEvents}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} >
                    <Form.Check 
                      type="checkbox"
                      name="otherContributionCheckbox"
                      label="Other"
                      value={props.values.otherContributionCheckbox || ''}
                      checked={props.values.otherContributionCheckbox}
                      onChange={props.handleChange}
                    />
                    <Form.Control
                      id="otherContributionText"
                      value={props.values.otherContributionText || ''}
                      onChange={props.handleChange}
                      isInvalid={props.errors.otherContributionText}
                    />
                    <Form.Control.Feedback type="invalid">{props.errors.otherContributionText}</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="feedbackText">
                  <Form.Label>Provide your feedback on the opportunities you hope to gain by joining an Official (ISC)² Chapter</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={props.values.feedbackText || ''}
                    onChange={props.handleChange}
                    isInvalid={props.errors.feedbackText}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors.feedbackText}</Form.Control.Feedback>
                </Form.Group>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Before Submitting your application, please review the (ISC)² Chapter Member Guidelines</Card.Title>
                <Form.Row>
                  <Form.Group as={Col} controlId="membershipAgreement">
                    <Form.Check 
                      type="checkbox" 
                      label="I Agree to the rules and requirements as outlined in the (ISC)² Chapter Member Guidelines"
                      value={props.values.membershipAgreement}
                      checked={props.values.membershipAgreement || ''} 
                      onChange={props.handleChange}
                      isInvalid={props.errors.membershipAgreement}
                      feedback={props.errors.membershipAgreement}
                    />
                  </Form.Group>
                </Form.Row>
              </Card.Body>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Card>
          </Form>
        )}
      </Formik>
      <SuccessModal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
        }}
      />
      <FailModal
        show={failModalShow}
        onHide={() => setFailModalShow(false)}
      />
    </>
  );
}

const Application = (props) => {
  const user = props.user;
  const token = props.token;
  const memberApplication = props.memberApplication

  return (
    <>
      <h4>Chapter Member Application</h4>
      <InputForm user={user} token={token} memberApplication={memberApplication} setMemberApplication={props.setMemberApplication}/>
    </>
  )
}

export default Application