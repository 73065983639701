import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Router } from "@reach/router"
import { Nav, Container } from "react-bootstrap"
import Seo from "../components/seo"
import { useAuth } from "react-use-auth"
import Home from "../components/accountHome"
import Profile from "../components/accountProfile"
import Application from "../components/accountApplication"
import Billing from "../components/accountBilling"
import firebase from "gatsby-plugin-firebase";

const Account = () => {
  const { isAuthenticated, user, authResult } = useAuth();
  const [memberApplication, setMemberApplication] = useState({
    firstname: "", 
    lastname: "",
    title: "",
    employer: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    primaryPhone: "",
    primaryEmail: "",
    secondaryEmail: "",
    isc2Member: false,
    isc2Id: "",
    otherProfessionalAssociations: "",
    professionalCertificates: "",
    areasOfSpecialization: "",
    chapterLeadership: false,
    professionalSpeaking: false,
    educationPrograms: false,
    communityOutreach: false,
    chapterEvents: false,
    otherContributionCheckbox: false,
    otherContributionText: "",
    feedbackText: "",
    membershipAgreement: false
  });

  useEffect(() => {
    if (typeof ((user || {})['https://isc2chapter-sacramento.org/user_metadata'] || {})['memberApplication'] != "undefined") {
      setMemberApplication(user['https://isc2chapter-sacramento.org/user_metadata']['memberApplication'])
    }
    localStorage.removeItem('useAuth:user');
  }, [user]);

  useEffect(() => {
    const getToken = async (accessToken) => {
      let response = await fetch(`${process.env.GATSBY_FIREBASE_FUNCTIONS}/token`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      let data = await response.json();
      return data;
    };
    firebase.auth().onAuthStateChanged(firebaseUser => {
      if(firebaseUser) {
        if(typeof user !== "undefined" && firebaseUser.email === null) {
          firebaseUser.updateEmail(user.email)
            .then()
            .catch(err => console.log(err));
          firebase.firestore().collection('users').doc(firebaseUser.uid).set({memberApplication: {}})
            .catch(err => console.log(err));
        }
      } else {
        if(typeof authResult !== "undefined" && authResult) {
          getToken(authResult.accessToken)
            .then(data => {
              firebase.auth().signInWithCustomToken(data.token)
                .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
        }
      }
    });
  }, [authResult, user]);

  if (!isAuthenticated()) {
    return <p>Please log in</p>
  }

  return (
    <>
      <Seo title="Account" />
      <h1>Account</h1>
      <Nav variant="tabs" defaultActiveKey="home">
        <Nav.Item>
          <Nav.Link as={Link} to="/account" eventKey="home" style={{padding: "0.5rem 0.75rem"}}>Home</Nav.Link>
        </Nav.Item>
{/*         <Nav.Item>
          <Nav.Link as={Link} to="/account/profile" eventKey="profile" style={{padding: "0.5rem 0.75rem"}} >Profile</Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link as={Link} to="/account/application" eventKey="application" style={{padding: "0.5rem 0.75rem"}}>Application</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/account/billing" eventKey="billing" style={{padding: "0.5rem 0.75rem"}}>Billing</Nav.Link>
        </Nav.Item>
      </Nav>
      <Container className="px-0 mt-2">
        <Router basepath="/account">
          <Home path="/" userName={user.name}/>
          <Profile path="/profile" />
          <Application path="/application" user={user} token={authResult} memberApplication={memberApplication} setMemberApplication={setMemberApplication} />
          <Billing path="/billing" />
        </Router>
      </Container>
    </>
  )
}

export default Account