import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Card, Button } from "react-bootstrap"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaShoppingCart } from "react-icons/fa"
import { useAuth } from "react-use-auth";

const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_API}`)

const redirectToCheckout = async (user, event) => {
  event.preventDefault()
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    items: [{plan: `${process.env.GATSBY_STRIPE_PRODUCT}`, quantity: 1}],
    successUrl: `${process.env.GATSBY_AUTH0_HOME}/dues_success`,
    cancelUrl: `${process.env.GATSBY_AUTH0_HOME}/dues_canceled`,
    customerEmail: `${user.email}`
  })

  if (error) {
    console.warn("Error:", error)
  }
}

const Checkout = () => {
  const { user } = useAuth();
  return (

    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "Master-Logo-Stacked.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width: 286, height: 180) {
                ...GatsbyImageSharpFixed
                }
            }
          }
        }
      `}
      render={data => (
        <>
          <Card style={{ width: '18rem' }}>
            <Img loading="eager" fixed={data.file.childImageSharp.fixed} />
            <Card.Body>
              <Card.Title>Annual Membership Subscription</Card.Title>
              <Card.Text>
                The Annual Membership Subscription helps to support this chapter's operations.
              </Card.Text>
              <Button variant="primary" onClick={(event) => redirectToCheckout(user, event)}><FaShoppingCart  style={{marginRight: "3px"}}/>Pay Now</Button>
            </Card.Body>
          </Card> 
        </>
      )}
    />
  )
}

export default Checkout